import { styled } from 'src/gatsby-theme-stitches/config'

export const ResultContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '> h2': {
    textAlign: 'center',
    padding: '2.5rem 0',
    fontFamily: 'Lato, sans-serif',
  },
})

export const ImageContainer = styled('div', {
  display: 'flex',
  position: 'relative',

  img: {
    width: '100%',
  },

  '.text': {
    position: 'absolute',
    color: '$onPrimary',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '75%',
    inset: '0',
    left: '7.5%',
    fontFamily: 'Lato, sans-serif',

    span: {
      fontSize: '1.5rem',
      fontWeight: 'lighter',
      textTransform: 'uppercase',

      '@sm': {
        fontSize: '.875rem',
      },
    },

    h4: {
      fontSize: '2.5rem',
      fontWeight: 'bold',

      '@sm': {
        fontSize: '1.5rem',
      },
    },
  },
})

export const ProductContainer = styled('div', {
  display: 'flex',
  gap: '2.5rem',
  width: '90%',
  margin: '0 auto 3rem',

  '@sm': {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0',
  },

  '.image-wrapper': {
    position: 'relative',
    width: '27.5rem',
    height: '27.5rem',
    backgroundColor: '$onPrimary',
    top: '-9.5rem',
    display: 'grid',
    placeItems: 'center',

    '@sm': {
      top: '-2.75rem',
      width: '90%',
      height: '20.75rem',
      mb: '-3rem',
    },

    '&::before': {
      position: 'absolute',
      content: '',
      size: '90%',
      border: '2px solid #000',
      inset: '0',
      margin: 'auto',
      borderTopLeftRadius: '74px',
      borderBottomRightRadius: '74px',
    },

    img: {
      width: '75%',
    },
  },
})

export const ProductDetails = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  pt: '2.5rem',
  width: '50%',
  fontFamily: 'Lato, sans-serif',

  '@sm': {
    width: '100%',
  },

  '.product-name': {
    fontSize: '2rem',
    fontWeight: 'bold',
  },

  '.price': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '@sm': {
      flexDirection: 'column',
      gap: '2rem',

      '> div': {
        '&:first-of-type': {
          alignSelf: 'flex-start',
        },
      },
    },

    '.list-price': {
      textDecoration: 'line-through',
      color: '#94a1a6',
      fontSize: '.875rem',
    },

    p: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
    },

    '.buy-button': {
      width: '20rem',
    },
  },

  '.description': {
    div: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },

    p: {
      width: '65%',
      fontSize: '.875rem',
      lineHeight: '1.5rem',

      '@sm': {
        width: '100%',
      },
    },
  },
})

export const ShelfContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  maxWidth: '90%',
  mx: 'auto',

  '> p': {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    fontFamily: 'Lato, sans-serif',
  },
})
