import React from 'react'
import { graphql } from 'gatsby'
import { SuspenseSSR } from '@vtex/store-ui'
import type { PageProps } from 'gatsby'
import Layout from 'src/components/Layout'
import QuizPerfumeResult from 'src/components/quiz/perfume/Result'
import OtherWaysToSearch from 'src/components/quiz/perfume/OtherWaysToSearch'
import type { DynamicShelfType } from 'src/typings/sections'
import { Loading } from 'src/components/ui/Loading'

import type { QuizPerfumeResultQueryQuery } from './__generated__/QuizPerfumeResultQuery.graphql'

type Props = PageProps<QuizPerfumeResultQueryQuery>

const Page = ({ data }: Props) => {
  const sections = data.cmsInstitutionalPage?.sections

  const perfumeBanners = sections?.filter(
    (section) => section.name === 'Banners'
  )

  const intensityShelf = sections?.filter(
    (section) => section.name === 'DynamicShelf'
  ) as DynamicShelfType[]

  return (
    <Layout>
      <SuspenseSSR fallback={<Loading />}>
        <QuizPerfumeResult intensityShelf={intensityShelf} />
      </SuspenseSSR>

      {perfumeBanners && (
        <OtherWaysToSearch perfumes={perfumeBanners[0].data} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query QuizPerfumeResultQuery {
    cmsInstitutionalPage(name: { eq: "Quiz Perfume" }) {
      sections {
        name
        data
      }
      seo {
        siteMetadataWithSlug {
          title
          description
          titleTemplate
          slug
        }
      }
    }
  }
`

export default Page
