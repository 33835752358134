

/**
 * Warning: This is an autogenerated file.
 *
 * Changes in this file won't take effect and will be overwritten
 */


// Operation related types
export type QuizPerfumesProductQueryQueryVariables = Exact<{
  productId: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type QuizPerfumesProductQueryQuery = { vtex: { productsByIdentifier: Maybe<Array<Maybe<{ productName: Maybe<string>, description: Maybe<string>, linkText: Maybe<string>, items: Maybe<Array<Maybe<{ itemId: Maybe<string>, sellers: Maybe<Array<Maybe<{ commertialOffer: Maybe<{ listPrice: Maybe<number>, price: Maybe<number>, availableQuantity: Maybe<number> }> }>>>, images: Maybe<Array<Maybe<{ imageUrl: Maybe<string> }>>> }>>> }>>> } };


// Query Related Code

export const QuizPerfumesProductQuery = {
  query: process.env.NODE_ENV === 'production' ? undefined : "query QuizPerfumesProductQuery($productId: [ID!]) {\n  vtex {\n    productsByIdentifier(field: id, values: $productId) {\n      productName\n      description\n      linkText\n      items {\n        itemId\n        sellers {\n          commertialOffer {\n            listPrice: ListPrice\n            price: Price\n            availableQuantity: AvailableQuantity\n          }\n        }\n        images {\n          imageUrl\n        }\n      }\n    }\n  }\n}\n",
  sha256Hash: "8cfafdd8099188c713a7703b73ab56ebdf0fe9855e7ce3d935532949f0c8d68d",
  operationName: "QuizPerfumesProductQuery",
}

