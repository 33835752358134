import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'
import { useOrderItems, useQuery } from '@vtex/gatsby-theme-store'
import { Button } from '@naturacosmeticos/natds-react'
import { formatPrice } from 'src/utils/formatPrice'
import type { DynamicShelfType } from 'src/typings/sections'
import ProductSlider from 'src/components/design-system/product/ProductSlider'
import { Loading } from 'src/components/ui/Loading'
import { useSession } from '@vtex/store-sdk'

import type { QuizPerfumesProductQueryQueryVariables } from './__generated__/QuizPerfumesProductQuery.graphql'
import { QuizPerfumesProductQuery } from './__generated__/QuizPerfumesProductQuery.graphql'
import {
  ImageContainer,
  ProductContainer,
  ProductDetails,
  ResultContainer,
  ShelfContainer,
} from './styled'
import type { Answers } from '../Questions'

interface ProductQuery {
  vtex: {
    productsByIdentifier: Array<{
      productName: string
      description: string
      linkText: string
      items: Array<{
        itemId: string
        images: Array<{
          imageUrl: string
        }>
        sellers: Array<{
          commertialOffer: {
            availableQuantity: number
            listPrice: number
            price: number
          }
        }>
      }>
    }>
  }
}

interface Props {
  intensityShelf: DynamicShelfType[]
}

export default function QuizPerfumeResult({ intensityShelf }: Props) {
  const { searchParams } = new URL(window.location.href)
  const productId = searchParams.get('productId')

  const [ingredients, setIngredients] = useState<string>('Madeira')
  const [shelf, setShelf] = useState<DynamicShelfType>(intensityShelf[0])

  const { data, isValidating } = useQuery<
    ProductQuery,
    QuizPerfumesProductQueryQueryVariables
  >({
    ...QuizPerfumesProductQuery,
    variables: {
      productId,
    },
  })

  const answers = localStorage.getItem(
    'quizPerfumes-answers'
  ) as unknown as Answers

  const { addItems } = useOrderItems()
  const { channel } = useSession()

  useEffect(() => {
    switch (answers?.ingredients) {
      case 1:
        setIngredients(answers.gender === 'masculino' ? 'Adocicado' : 'Floral')
        break

      case 2:
        setIngredients(answers.gender === 'masculino' ? 'Madeira' : 'Adocicado')
        break

      case 3:
        setIngredients(answers.gender === 'masculino' ? 'Ervas' : 'Madeira')
        break

      case 4:
        setIngredients(answers.gender === 'masculino' ? 'Cítrico' : 'Ervas')
        break

      case 5:
        setIngredients('Cítrico')
        break

      default:
        setIngredients('Madeira')
        break
    }

    switch (answers?.intensity) {
      case 1:
        setShelf(intensityShelf[0])
        break

      case 2:
        setShelf(intensityShelf[1])
        break

      case 3:
        setShelf(intensityShelf[2])
        break

      default:
        setShelf(intensityShelf[0])
        break
    }
  }, [answers, intensityShelf])

  if (isValidating) {
    return (
      <ResultContainer>
        <Loading />
      </ResultContainer>
    )
  }

  const product = data?.vtex.productsByIdentifier?.[0]

  if (!product) {
    return (
      <>
        <ResultContainer>
          <h2>Produto não encontrado</h2>
        </ResultContainer>

        <ShelfContainer>
          <p>{shelf.data.title}</p>

          <ProductSlider
            searchParams={shelf.data.searchParams}
            title={shelf.data.title}
          />
        </ShelfContainer>
      </>
    )
  }

  const listPrice = formatPrice(
    product.items[0].sellers[0].commertialOffer.listPrice
  )

  const price = formatPrice(product?.items[0].sellers[0].commertialOffer.price)

  const handleBuyProduct = async () => {
    await addItems(
      [{ id: product.items[0].itemId, quantity: 1, seller: '1' }],
      {
        salesChannel: String(channel),
      }
    )

    navigate('/checkout')
  }

  return (
    <>
      <ResultContainer>
        <ImageContainer>
          <div>
            <img
              src="/assets/quiz-perfume/avon-banner-result.png"
              alt="Avon Banner"
            />
          </div>

          <div className="text">
            <span>Seus perfumes</span>
            <h4>{ingredients}</h4>
          </div>
        </ImageContainer>

        <ProductContainer>
          <div className="image-wrapper">
            <img src={product.items[0].images[0].imageUrl} alt="" />
          </div>

          <ProductDetails>
            <p className="product-name">{product.productName}</p>

            <div className="price">
              <div>
                <span className="list-price">{listPrice}</span>
                <p>{price}</p>
              </div>

              <div className="buy-button">
                <Button onClick={handleBuyProduct} fullWidth>
                  Comprar
                </Button>
              </div>
            </div>

            <div className="description">
              <div>Descrição</div>
              <p dangerouslySetInnerHTML={{ __html: product.description }} />
            </div>
          </ProductDetails>
        </ProductContainer>
      </ResultContainer>

      <ShelfContainer>
        <p>{shelf.data.title}</p>

        <ProductSlider
          searchParams={shelf.data.searchParams}
          title={shelf.data.title}
        />
      </ShelfContainer>
    </>
  )
}

export const query = graphql`
  query QuizPerfumesProductQuery($productId: [ID!]) {
    vtex {
      productsByIdentifier(field: id, values: $productId) {
        productName
        description
        linkText
        items {
          itemId
          sellers {
            commertialOffer {
              listPrice: ListPrice
              price: Price
              availableQuantity: AvailableQuantity
            }
          }
          images {
            imageUrl
          }
        }
      }
    }
  }
`
