import { Center, Flex, Spinner } from '@vtex/store-ui'
import React from 'react'

export const Loading = () => {
  return (
    <Flex>
      <Center>
        <Spinner />
      </Center>
    </Flex>
  )
}
